/*
   generated by rouge http://rouge.jneen.net/
   original base16 by Chris Kempson (https://github.com/chriskempson/base16)
*/

@import "default_colors";

.highlight {

  $plaintext: $alto !default;
  $string: $chelsea-cucumber !default;
  $literal: $chelsea-cucumber !default;
  $keyword: $bouquet !default;
  $error-foreground: $cod-grey !default;
  $error-background: $apple-blossom !default;
  $comment: $grey !default;
  $preprocessor: $rajah !default;
  $name-space: $rajah !default;
  $name-attribute: $hippie-blue !default;
  $operator: $rajah !default;
  $keyword-type: $raw-sienna !default;
  $regex: $gulf-stream !default;
  $string-escape: $potters-clay !default;
  $deleted: $apple-blossom !default;
  $header: $hippie-blue !default;

  color: $plaintext;

  table td { padding: 5px; }
  table pre { margin: 0; }
  .w {
    color: $plaintext;
  }
  .err {
    color: $error-foreground;
    background-color: $error-background;
  }
  .c,  .cd,  .cm,  .c1,  .cs {
    color: $comment;
  }
  .cp {
    color: $preprocessor;
  }
  .o,  .ow {
    color: $operator;
  }
  .p,  .pi {
    color: $plaintext;
  }
  .gi {
    color: $string;
  }
  .gd {
    color: $deleted;
  }
  .gh {
    color: $header;
    font-weight: bold;
  }
  .k,  .kn,  .kp,  .kr,  .kv {
    color: $keyword;
  }
  .kc, .kt, .kd {
      color: $keyword-type;
  }
  .s,  .sb,  .sc,  .sd,  .s2,  .sh,  .sx,  .s1 {
    color: $string;
  }
  .sr {
    color: $regex;
  }
  .si, .se {
    color: $string-escape;
  }
  .nt, .nn,  .nc, .no{
    color: $name-space;
  }
  .na {
    color: $name-attribute;
  }
  .m,  .mf,  .mh,  .mi,  .il,  .mo,  .mb,  .mx {
    color: $literal;
  }
  .ss {
    color: $string;
  }
}
